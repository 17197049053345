@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

#pokemon-info-container {
	margin-top: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#loading-message {
	margin: 2em 0;
}

#toggle-arrows {
	margin: 0.5em 0;
	border: none;
	font-size: 1.25em;
	font-weight: 900;
	background-color: #fafafa;
  font-family: 'Press Start 2P', 'sans serif';
  cursor: pointer;
}
