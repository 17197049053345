@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');

#pokedex-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	font-size: 14px;
	text-align: center;
}

#pokedex-main {
	width: 350px;
	margin: 2em 0;
}

h1 {
	font-size: 2.5em;
	margin-bottom: 1em;
}

h2 {
	font-size: 1.5em;
}

h3 {
	font-size: 1.25em;
}

.form {
	margin: 0.5em;
}

.label {
	font-size: 1em;
}

.input-field {
	height: 20px;
	border: 1px solid #000;
	border-radius: 0.25em;
	padding: 0.25em;
	width: 175px;
  font-family: 'Press Start 2P', 'sans serif';
  margin-right: .5em;
}

.button {
	height: 27px;
	border: 1px solid #000;
	border-radius: 0.25em;
	padding: 0.5em;
  font-family: 'Press Start 2P', 'sans serif';
  background-color: #cfcfcf;
  cursor: pointer;
}

.footer-text {

}
