#basic-info-border {
	border: 3px solid #767676;
	border-radius: 0.20em;
	margin: 1em 0em;
}

#basic-info-container {
	border: 2px solid #333;
	background-color: #fafafa;
	width: 350px;
}

#top-section-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
}

#sprite-container {
	margin: 0;
	width: 55%;
}

.sprite-image {
	height: 150px;

}

.tiny-text {
	font-size: 0.75em;
}

#vitals {
	width: 45%;
	padding-left: 1em;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
}

#vitals p {
	margin-bottom: 0;
	margin-top: 2em;
}

.bold-stats {
	font-weight: 900;
}

#pokemon-description {
	text-align: left;
	padding: 1.5em;
}

#pokemon-description p {
	line-height: 2em;
	margin: 0;
}

#boxes {
	position: relative;
	top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

#box-group-left {
	margin-right: 2em;
	margin-left: 0.5em;
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

#box-group-right {
	margin-right: 0.5em;
	margin-left: 2em;
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.box {
	width: 6px;
	height: 6px;
	border: 2px solid #000;
	background-color: #fafafa;
}

hr {
	margin-top: 1.75em;
	margin-bottom: 0;
	border: 1px solid #000;	
}
