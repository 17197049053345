@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Press Start 2P', 'sans serif';
  font-size: 18px;
  background-color: #fafafa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a, a:hover {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

footer {
  margin-bottom: 2em;
}

.footer-text {
  font-size: 0.5em;
  text-align: center;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#basic-info-border {
	border: 3px solid #767676;
	border-radius: 0.20em;
	margin: 1em 0em;
}

#basic-info-container {
	border: 2px solid #333;
	background-color: #fafafa;
	width: 350px;
}

#top-section-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
}

#sprite-container {
	margin: 0;
	width: 55%;
}

.sprite-image {
	height: 150px;

}

.tiny-text {
	font-size: 0.75em;
}

#vitals {
	width: 45%;
	padding-left: 1em;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
}

#vitals p {
	margin-bottom: 0;
	margin-top: 2em;
}

.bold-stats {
	font-weight: 900;
}

#pokemon-description {
	text-align: left;
	padding: 1.5em;
}

#pokemon-description p {
	line-height: 2em;
	margin: 0;
}

#boxes {
	position: relative;
	top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

#box-group-left {
	margin-right: 2em;
	margin-left: 0.5em;
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

#box-group-right {
	margin-right: 0.5em;
	margin-left: 2em;
	width: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.box {
	width: 6px;
	height: 6px;
	border: 2px solid #000;
	background-color: #fafafa;
}

hr {
	margin-top: 1.75em;
	margin-bottom: 0;
	border: 1px solid #000;	
}

#stats-border {
	margin: 1em 0em;
}

#top-section-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
}

#sprite-container {
	margin: 0;
	width: 50%;
}

.sprite-image {
	height: 150px;
}

.tiny-text {
	font-size: 0.75em;
}

#health-status {
	width: 50%;
	text-align: left;
	border-right: 4px solid #000;
	border-bottom: 4px solid #000;
	border-radius: 0.25em;
	padding-right: .25em;
	padding-bottom: .25em;
}

#health-status p {
	margin-bottom: 0;
	margin-top: 1em;
}

.centered {
	text-align: center;
}

.tinier-text {
	font-size: 0.6em;
}

#level {
	margin-right: 3em;
}

#hp-bar-container {
	text-align: right;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding-right: 0.5em;
}

#hp-bar-container p {
	margin-top: 0.25em;
}

#hp-bar {
	width: 60%;
	height: 5px;
	border: 2px solid #000;
	border-radius: .99em;
	margin-top: 0.5em;
	background-color: #606060;
}

#hp-data p {
	margin-top: 0.5em;
	text-align: right;
	padding-right: 0.5em;
}

#detailed-stats {
	margin-top: 1em;
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	height: 235px;
}

.pokeball {
  position: absolute;
	width: 8px;
	height: 8px;
	border: 2px solid #000;
	border-radius: 50%;
  background-color: #fafafa;
}

.pokeball-upper {
	position: absolute;
	left: 0px;
	height:4px;
	width:8px;
	border-radius: 8px 8px 0 0;
	background-color: #000;
}

.top-left {
	top: -8px;
	left: -8px;
}

.top-right {
	top: -8px;
	right: -8px
}

.bottom-left {
	bottom: -8px;
	left: -8px
}

.bottom-right {
	bottom: -8px;
	right: -8px
}

#basic-stats-box {
	border: 5px double #000;
	margin: 2px;
	padding: 3px;
	position: absolute;
	width: 150px;
}

.left-align {
	text-align: left;
}

.right-align {
	text-align: right;
}

.bold-stats {
	font-weight: 900;
}

#basic-stats-box p {
	margin: 0.5em;

}

#type-panel {
	position: relative;
	width: 150px;
	text-align: left;
	margin-left: 13em;
	padding-left: 1em;
	border-right: 4px solid #000;
	border-bottom: 4px solid #000;
	border-radius: 0.25em;
	padding-right: .25em;
	padding-bottom: .25em;
}

#type-panel p {
	margin-top: 1em;
	margin-bottom: 0;
}

.indented {
	padding-left: 2em;
}

#pokemon-info-container {
	margin-top: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

#loading-message {
	margin: 2em 0;
}

#toggle-arrows {
	margin: 0.5em 0;
	border: none;
	font-size: 1.25em;
	font-weight: 900;
	background-color: #fafafa;
  font-family: 'Press Start 2P', 'sans serif';
  cursor: pointer;
}

#pokedex-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	font-size: 14px;
	text-align: center;
}

#pokedex-main {
	width: 350px;
	margin: 2em 0;
}

h1 {
	font-size: 2.5em;
	margin-bottom: 1em;
}

h2 {
	font-size: 1.5em;
}

h3 {
	font-size: 1.25em;
}

.form {
	margin: 0.5em;
}

.label {
	font-size: 1em;
}

.input-field {
	height: 20px;
	border: 1px solid #000;
	border-radius: 0.25em;
	padding: 0.25em;
	width: 175px;
  font-family: 'Press Start 2P', 'sans serif';
  margin-right: .5em;
}

.button {
	height: 27px;
	border: 1px solid #000;
	border-radius: 0.25em;
	padding: 0.5em;
  font-family: 'Press Start 2P', 'sans serif';
  background-color: #cfcfcf;
  cursor: pointer;
}

.footer-text {

}

